import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'core/components/Icon.js';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';

export default class Dropdown extends React.Component {
    static propTypes = {
        buttonRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.shape({current: PropTypes.instanceOf(Element)}),
        ]),
        customClass: PropTypes.string,
        displayElement: PropTypes.object,
        displayText: PropTypes.string,
        dropdownHeading: PropTypes.element,
        handleMouseDown: PropTypes.func,
        icon: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                content: PropTypes.element,
            }),
        ),
        optionsClassName: PropTypes.string,
        selectedOption: PropTypes.string,
        shortText: PropTypes.string,
        storeIsOpen: PropTypes.bool,
    };

    static defaultProps = {
        customClass: '',
        options: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: props.storeIsOpen || false,
            focusedIdx: -1,
        };

        this.dropdownMenu = React.createRef();
        this.dropdownContent = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleDocumentClick);
        document.addEventListener('touchend', this.handleDocumentClick);
        document.addEventListener('keydown', this.handleDocumentKeyDown);
    }

    componentDidUpdate(prevProps) {
        const {storeIsOpen, stopAutoScroll} = this.props;
        if (prevProps.storeIsOpen !== storeIsOpen) {
            this.setState({isOpen: storeIsOpen});
        }
        if (!stopAutoScroll) {
            const focusedOption = document.querySelector(
                '.drop-down-body [aria-current="true"]',
            );
            if (focusedOption) {
                focusedOption.scrollIntoView(false);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
        document.removeEventListener('touchend', this.handleDocumentClick);
        document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    @autobind
    handleDocumentKeyDown(event) {
        if (
            event.type === 'keydown' &&
            (event.key === 'Escape' || event.key === 'Esc')
        ) {
            this.closeDropdown();
        }
    }

    @autobind
    handleKeyDown(event) {
        const {options} = this.props;
        event.preventDefault();
        const key = event.key;
        const {focusedIdx} = this.state;
        let currentFocus = focusedIdx;
        switch (key) {
            case 'ArrowDown':
                if (currentFocus === options.length - 1) {
                    currentFocus = 0;
                } else {
                    currentFocus += 1;
                }
                break;
            case 'ArrowUp':
                if (currentFocus <= 0) {
                    currentFocus = options.length - 1;
                } else {
                    currentFocus -= 1;
                }
                break;
            case 'Enter': {
                this.selectOption(currentFocus);
                break;
            }
        }
        this.setState({focusedIdx: currentFocus});
    }

    selectOption(optionIndex) {
        // clicks the dropdown option at the provided index
        const selectedElement = this.dropdownContent.current.childNodes[
            optionIndex
        ];
        selectedElement.firstElementChild.click();
    }

    @autobind
    handleDocumentClick(event) {
        const {handleMouseDown} = this.props;
        if (handleMouseDown) {
            handleMouseDown(event);
        }
        if (
            this.state.isOpen &&
            this.dropdownMenu.current &&
            !this.dropdownMenu.current.contains(event.target)
        ) {
            if (event.target.tagName !== 'HTML') {
                this.closeDropdown();
            }
        }
    }

    closeDropdown() {
        if (this.props.handleToggle) {
            this.props.handleToggle(false);
        }
        this.setState({isOpen: false});
    }

    @autobind
    toggleDropdown() {
        let focusedIdx = -1;
        if (this.props.selectedOption) {
            focusedIdx = this.props.options.findIndex(
                (option) => option.name === this.props.selectedOption,
            );
        }
        this.setState(
            {
                focusedIdx,
                isOpen: !this.state.isOpen,
            },
            () => {
                const {handleToggle} = this.props;
                const {isOpen} = this.state;
                if (isOpen) {
                    this.dropdownContent.current?.firstElementChild?.focus();
                }
                if (handleToggle) {
                    handleToggle(isOpen);
                }
            },
        );
    }

    renderButtonContent() {
        const {displayElement, displayText, shortText} = this.props;
        if (displayElement) {
            return displayElement;
        }
        return (
            <>
                <span>{displayText}</span>
                {shortText && <span>{shortText}</span>}
                <Icon name="dropDown" />
            </>
        );
    }

    renderOptions() {
        const {
            children,
            options,
            optionsClassName,
            selectedOption,
        } = this.props;

        if (children) {
            return children;
        }

        return (
            <ul ref={this.dropdownContent}>
                {options.map((option, index) => {
                    const isSelected = option.name === selectedOption;
                    return (
                        <li
                            aria-current={this.state.focusedIdx === index}
                            aria-selected={isSelected}
                            className={optionsClassName}
                            key={`option-${option.name}-${index}`}
                            onKeyDown={this.handleKeyDown}
                            tabIndex={index}
                        >
                            {option.content}
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        const {isOpen} = this.state;
        const {customClass, gaSelector, icon, dropdownHeading} = this.props;
        return (
            <div
                aria-expanded={isOpen}
                className={`drop-down ${customClass}`.trim()}
                ref={this.dropdownMenu}
            >
                <Button
                    action={this.toggleDropdown}
                    customClass="button-handle"
                    customRef={this.props.buttonRef}
                    gaSelector={gaSelector}
                    icon={icon}
                    text={this.renderButtonContent()}
                />
                {isOpen && (
                    <section className="drop-down-body">
                        {dropdownHeading}
                        {this.renderOptions()}
                    </section>
                )}
            </div>
        );
    }
}
