import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import events from 'events.js';
import http from 'http.js';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Modal from 'core/components/Modal.js';
import {isValidEmailAddress} from 'utils/validation.js';

export default class FeedbackModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            emailInput: '',
            nameInput: '',
            showDescriptionError: false,
            showEmailError: false,
            submitDone: false,
            submitError: false,
            submitting: false,
        };
    }

    @autobind
    async submitFeedback(event) {
        event.preventDefault();

        if (this.displayErrorMessages()) {
            return null;
        }

        const {description, emailInput, nameInput} = this.state;
        const data = {
            description,
        };
        if (emailInput) {
            data['contact_email'] = emailInput;
        }
        if (nameInput) {
            data['name'] = nameInput;
        }
        const url = '/api/professionals/v1/referrals-feedback/';
        const newState = {};
        try {
            this.setState({submitting: true});
            await http.post({url, data});
        } catch (error) {
            newState.submitError = true;
        } finally {
            newState.submitDone = true;
            newState.submitting = false;
            this.setState(newState);
        }
    }

    componentDidMount() {
        events.listen(document.body, 'click', this.handleClick);
    }

    componentWillUnmount() {
        events.unlisten(document.body, 'click', this.handleClick);
    }

    @autobind
    handleClick() {
        this.setState({
            showDescriptionError: false,
            showEmailError: false,
        });
    }

    @autobind
    handleNameInput(e) {
        this.setState({nameInput: e.currentTarget.value});
    }

    @autobind
    handleEmailInput(e) {
        this.setState({emailInput: e.currentTarget.value});
    }

    @autobind
    handleDescriptionChange(e) {
        this.setState({description: e.currentTarget.value});
    }

    displayErrorMessages() {
        const {description, emailInput} = this.state;
        const showDescriptionError = !description.trim().length;
        const showEmailError =
            isValidEmailAddress(emailInput) && emailInput.trim().length > 0;
        this.setState({
            showDescriptionError,
            showEmailError,
        });
        return showDescriptionError || showEmailError;
    }

    renderRequiredToolTip(message) {
        return (
            <span className="notice" role="alert">
                {message}
            </span>
        );
    }

    @autobind
    renderSubmitForm() {
        const {
            description,
            emailInput,
            nameInput,
            showDescriptionError,
            showEmailError,
            submitting,
        } = this.state;
        return (
            <form className="report-modal">
                <label>
                    {showDescriptionError &&
                        this.renderRequiredToolTip(
                            'Please provide description of the issue',
                        )}
                    {'Description:'}
                    <textarea
                        id="description"
                        onChange={this.handleDescriptionChange}
                        placeholder="Please provide a description in as much detail as possible"
                        value={description}
                    />
                </label>
                {
                    'Please consider leaving your contact details in case we need to follow up with you. '
                }
                <label>
                    {'Your name '}
                    <em>{'(optional)'}</em>
                    <input
                        onChange={this.handleNameInput}
                        type="text"
                        value={nameInput}
                    />
                </label>
                <label>
                    {showEmailError &&
                        this.renderRequiredToolTip(
                            'Please provide a valid email address',
                        )}
                    {'Your email address '}
                    <em>{'(optional)'}</em>
                    <input
                        onChange={this.handleEmailInput}
                        type="email"
                        value={emailInput}
                    />
                </label>
                <Button
                    action={this.submitFeedback}
                    customClass="cta"
                    disabled={submitting}
                    text={'Submit feedback'}
                    type="submit"
                />
            </form>
        );
    }

    @autobind
    handleReturnToFeedback() {
        this.setState({
            submitDone: false,
        });
    }

    renderSubmitMessage() {
        const {submitError} = this.state;
        if (submitError) {
            return (
                <>
                    <p className="notification error">
                        {
                            'Sorry an error has occurred, please refresh and try again.'
                        }
                    </p>
                    <Button
                        action={this.handleReturnToFeedback}
                        customClass="cta"
                        text="Return to feedback"
                    />
                </>
            );
        } else {
            return (
                <p className="notification success">
                    {
                        'Thank you for providing this information. We will look into this and aim to action it as soon as possible.'
                    }
                </p>
            );
        }
    }

    render() {
        const {submitDone} = this.state;
        const {closeModal} = this.props;
        return ReactDOM.createPortal(
            <Modal
                closeModal={closeModal}
                initElement={document.activeElement}
                parentElement={document.activeElement.parentElement}
            >
                <>
                    <Button
                        action={closeModal}
                        customClass="dismiss"
                        icon="close"
                        text=""
                    />
                    <h1>{'Feedback / Report error'}</h1>
                    {submitDone
                        ? this.renderSubmitMessage()
                        : this.renderSubmitForm()}
                </>
            </Modal>,
            document.body,
        );
    }
}
