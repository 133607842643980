import PropTypes from 'prop-types';
import React from 'react';

import IntegratedMenu from 'base/components/IntegratedMenu.js';
import PrimaryMenu from 'base/components/PrimaryMenu.js';
import SearchForm from 'search/components/SearchForm.js';

export default class Menu extends React.Component {
    static propTypes = {
        allowSignup: PropTypes.bool,
        client: PropTypes.string,
        isHomePage: PropTypes.bool,
        isReferralsLandingPage: PropTypes.bool,
        isReferralsPage: PropTypes.bool,
        networkImg: PropTypes.string,
        networkReferralsUrl: PropTypes.string,
        networkUrl: PropTypes.string,
        registerUrl: PropTypes.string,
        showAccountMenu: PropTypes.bool,
        showFactsheets: PropTypes.bool,
        showReferrals: PropTypes.bool,
        showSearch: PropTypes.bool,
    };

    static defaultProps = {
        allowSignup: true,
        client: '',
        showAccountMenu: true,
        showFactsheets: true,
        showReferrals: true,
        showSearch: true,
    };

    get renderSearchForm() {
        const {isHomePage, isReferralsLandingPage, isReferralsPage} =
            this.props;
        return !isReferralsPage && !isHomePage && !isReferralsLandingPage;
    }

    render() {
        const {
            allowSignup,
            client,
            interest,
            isReferralsLandingPage,
            isReferralsPage,
            networkImg,
            networkUrl,
            registerUrl,
            showAccountMenu,
            specialty,
        } = this.props;
        return (
            <>
                {client ? (
                    <IntegratedMenu isReferralsPage={isReferralsPage} />
                ) : (
                    <PrimaryMenu
                        allowSignup={allowSignup}
                        networkImg={networkImg}
                        networkUrl={networkUrl}
                        registerUrl={registerUrl}
                        showAccountMenu={showAccountMenu}
                    />
                )}
                {this.renderSearchForm && (
                    <section>
                        <SearchForm
                            isClientReferralsPage={isReferralsPage && !!client}
                        />
                    </section>
                )}
            </>
        );
    }
}
