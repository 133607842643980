import React from 'react';

import AccountMenu from 'base/components/AccountMenu.js';
import PartnerMenu from 'base/components/PartnerMenu.js';

import Icon from 'core/components/Icon.js';

export default class PrimaryMenu extends React.Component {
    render() {
        const {
            allowSignup,
            networkImg,
            networkUrl,
            registerUrl,
            showAccountMenu,
        } = this.props;
        const homeUrl = networkUrl ? networkUrl : '/';
        return (
            <section>
                <a className="mark" href={homeUrl}>
                    <Icon name="mark" />
                    <strong>{'HealthShare'}</strong>
                    <em>{'.com.au'}</em>
                </a>
                {networkImg && <img src={networkImg} />}
                {showAccountMenu && (
                    <AccountMenu
                        allowSignup={allowSignup}
                        registerUrl={registerUrl}
                    />
                )}
                <PartnerMenu />
            </section>
        );
    }
}
