import PropTypes from 'prop-types';
import React from 'react';

import Button from 'core/components/Button.js';
import FeedbackModal from 'core/components/FeedbackModal.js';
import autobind from 'common/decorators/autobind.js';
import {FIND_A_PROVIDER} from 'core/constants.js';

export default class IntegratedMenu extends React.Component {
    static propTypes = {
        isReferralsPage: PropTypes.bool,
    };

    static defaultProps = {
        isReferralsPage: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
        };
    }

    handleBackAction() {
        window.history.back();
    }

    @autobind
    handleFeedbackAction() {
        this.setState({
            modalVisible: true,
        });
    }

    @autobind
    closeModal() {
        this.setState({
            modalVisible: false,
        });
    }

    render() {
        return (
            <>
                {this.state.modalVisible && (
                    <FeedbackModal closeModal={this.closeModal} />
                )}
                <section>
                    <nav>
                        {!this.props.isReferralsPage && (
                            <Button
                                action={this.handleBackAction}
                                text={'Back'}
                            />
                        )}
                        <Button
                            action={this.handleFeedbackAction}
                            text={'Feedback / Report error'}
                        />
                    </nav>
                </section>
            </>
        );
    }
}
