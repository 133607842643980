import React from 'react';

import Dropdown from 'core/components/Dropdown.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

const links = [
    {
        icon: 'specialists',
        text: 'Specialists',
        url: '/specialists/',
    },
    {
        icon: 'generalPractitioners',
        text: 'GPs',
        url: '/gps/',
    },
    {
        icon: 'pharmaceutical',
        text: 'Pharmaceutical',
        url: '/pharmaceutical/',
    },
    {
        icon: 'healthInsurers',
        text: 'PHIs',
        url: '/phis/',
    },
];

export default class PartnerMenu extends React.Component {
    get options() {
        const url = 'https://www.healthsharedigital.com/audiences';
        return [...links].map((link) => ({
            name: link.text,
            content: (
                <Link href={url + link.url}>
                    <Icon name={link.icon} />
                    {link.text}
                </Link>
            ),
        }));
    }
    render() {
        return (
            <Dropdown
                customClass="company"
                displayText="Partner with us"
                options={this.options}
            />
        );
    }
}
