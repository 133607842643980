import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import SearchForm from 'search/components/SearchForm.js';
import {SearchStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class HomeSearch extends React.Component {
    static contextType = SearchStoreContext;
    static propTypes = {
        interest: PropTypes.object,
        isReferralsLandingPage: PropTypes.bool,
        specialty: PropTypes.object,
    };

    static defaultProps = {
        isReferralsLandingPage: false,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {interest, isReferralsLandingPage, specialty} = this.props;
        const profileCount = document.body.dataset.profileCount;
        const heading = `Find a ${
            this.context.specialty?.text || 'Health Professional'
        }`;

        return (
            <div
                className={`search-home-page ${
                    this.props.isReferralsLandingPage
                        ? 'referrals-landing'
                        : ''
                }`.trim()}
            >
                <div>
                    <h1>{heading}</h1>
                    <h3>{`Choose from ${
                        profileCount || '93,000'
                    } Australian Health Professionals`}</h3>
                    <SearchForm
                        interest={interest}
                        isReferralsLandingPage={isReferralsLandingPage}
                        specialty={specialty}
                    />
                </div>
            </div>
        );
    }
}
